import styled from "styled-components";

export const PreLineAuthProductWarrantyContainerStyled = styled.div`
  .logo_img {
    margin: auto;
    margin-bottom: 40px;
    max-width: 148px;
    width: 100%;
    height: auto;
  }
  .header_layout {
    margin-bottom: 1.25rem;
    &.mb_2rem {
      margin-bottom: 2rem;
    }
    .title {
      text-align: center;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_SEMIBOLD};
    }
  }
  .action_layout {
    margin-bottom: 2rem;
    display: felx;
    justify-content: center;
    a {
      text-decoration: none;
    }
    .btn_next {
      border-radius: 0.5rem;
      padding: 0.2rem 4rem;
      text-decoration: none;
      background: #06c755;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      cursor: pointer;
    }
  }
  .reccommend_layout {
    .rec_block {
      display: flex;
      column-gap: 0.5rem;
      &:first-child {
        margin-bottom: 0.5rem;
      }
      .rec_item {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S15};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
    }
  }
`;
