import { userService } from "apiServices";
import { IMG_PATH } from "utils/constants/imgPath";

export const GENERAET_CERIFICATE = (cerID) => {
  return new Promise(async (resolve) => {
    let res = await userService.GET_CERTIFICATE_LANDING_DETAIL(cerID);
    if (res && res.status === 200) {
      try {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // ล้าง Canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const template = IMG_PATH + res.data.certificate.img_path;
        if (template) {
          const img = new Image();
          img.crossOrigin = "anonymous"; // อนุญาต Cross-Origin
          img.src = template;

          // รอให้ภาพโหลดสำเร็จก่อน
          img.onload = () => {
            // ปรับขนาด canvas ให้เหมาะสมกับขนาดของรูป
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0, img.width, img.height); // วาด Background ให้เต็มพื้นที่
            ctx.font = `${res.data.certificate.cus_name_font_size}px Arial`;
            ctx.fillStyle = "black";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle"; // ตั้งค่าตำแหน่งข้อความกลาง
            // ctx.fillText(name, position.x, position.y); // วาดชื่อในตำแหน่งที่กำหนด
            ctx.fillText(
              res.data.myCertificate.first_name +
                " " +
                res.data.myCertificate.last_name,
              img.width / 2,
              res.data.certificate.cus_name_position_y
            ); // วาดชื่อในตำแหน่งที่กำหนด
            resolve({
              status: 200,
              data: canvas.toDataURL(),
            });
          };
        } else {
          resolve({
            status: 400,
          });
        }
      } catch (error) {
        resolve({
          status: 400,
        });
      }
    } else {
      resolve({
        status: 400,
      });
    }
  });
};
