import styled from "styled-components";

export const CustomerActFRegisItemStyled = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
  padding: 16px 4px;
  .mbr_name {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
  .mbr_status_info_row {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    .mrt_col {
      flex: 1;
      .mbr_status {
        border-radius: 12px;
        padding: 6px 6px;
        width: 100%;
        background: ${({ theme, statusBg }) =>
          statusBg ? statusBg : theme.COLORS.GREEN_2};
        text-align: center;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
        corsor: pointer;
      }
      a {
        text-decoration: none;
      }
      .si_item {
        border-radius: 12px;
        padding: 6px 6px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 4px;
        background: ${({ theme }) => theme.COLORS.GREEN_2};
        text-align: center;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
        cursor: pointer;
        text-decoration: none;
        &.bg_gold {
          background: ${({ theme }) => theme.COLORS.GOLD_1};
        }
        &.bg_yellow {
          background: ${({ theme }) => theme.COLORS.YELLOW_1};
        }
        &.bg_blue {
          background: ${({ theme }) => theme.COLORS.BLUE_1};
        }
        &.bg_orange {
          background: ${({ theme }) => theme.COLORS.ORANGE_1};
        }
        &.bg_purple {
          background: ${({ theme }) => theme.COLORS.PURPLE_1};
          cursor: pointer;
        }
      }
    }
  }

  .theme_standard {
  }
`;
